import React from "react";
import "./App.css";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171612193-1");
ReactGA.pageview("/");

function App() {
  return (
    <div className="body">
      <div className="about">
        <img
          src={require("./me.jpg")}
          alt="Avatar"
          width="256"
          height="256"
          className="image"
        />
        <Typography className="heading" variant="h4">
          Hi, I'm Aayush!
        </Typography>
        <div className="paragraph">
          <Typography align="left" color="textSecondary" variant="body1">
            I am currently a software engineer at{" "}
            <a
              href="https://www.facebook.com/aayush.bhutani.26/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#385898",
                textDecoration: "none",
                backgroundColor: "aliceblue"
              }}
            >
              Facebook
            </a>
            {". "}I graduated from{" "}
            <a
              href="https://www.cmu.edu/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#880000",
                textDecoration: "none",
                backgroundColor: "#FFF0F8"
              }}
            >
              Carnegie Mellon
            </a>
            {". "}
            <br />
            <br /> I love to code,{" "}
            <a
              href="https://airplaneaholic.aayushbhutani.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "green",
                textDecoration: "none",
                borderBottomStyle: "solid"
              }}
            >
              blog
            </a>{" "}
            and read books. I'd recommend checking out{" "}
            <a
              href="https://nextbooktoread.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#385898",
                textDecoration: "none",
                borderBottomStyle: "solid"
              }}
            >
              NextBook
            </a>{" "}
            for some book recommendations by well renowned people. <br />
            <br />I am always excited to talk about airplanes and startups, and
            meet new people. If you'd like to chat, click{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:aayubhutani@gmail.com?Subject=Hi!"
              style={{
                textDecoration: "none"
              }}
            >
              <span role="img" aria-label="hi">
                👋
              </span>
            </a>
            {"."}
            <br />
            <br />
            P.S. Check out{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://findremotework.co/"
              style={{
                color: "green",
                textDecoration: "none",
                borderBottomStyle: "solid"
              }}
            >
              Find Remote Work
            </a>{" "}
            for curated list of latest remote jobs opportunities from around the
            world.
          </Typography>
        </div>
        <div>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/aayushbhutani/"
            aria-label="linkedin"
          >
            <LinkedInIcon style={{width: 36, height: 36, color: "black"}} />
          </IconButton>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.producthunt.com/@aayubhutani"
            aria-label="product-hunt"
          >
            <img
              src={require("./ph-logo-black.png")}
              alt="Avatar"
              width="30"
              height="30"
              className="image"
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default App;
